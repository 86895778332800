import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Product from '../components/product';
import Pagination from '../components/pagination';
import Category from '../components/category';
import CategoryCard from '../components/categoryCard';

class PostList extends Component {
  render() {
    const { data, pageContext } = this.props;
    const posts = data.allWordpressPost;
    const { pageInfo } = posts;
    const { pageCount, currentPage } = pageInfo;
    const { wordpressCategory } = data;
    const breadcrumbs = [
      {
        data: [
          {
            name: wordpressCategory.name,
            url: `category/${wordpressCategory.slug}/1/`,
          },
        ],
      },
    ];
    return (
      <Layout showHeader={true} breadcrumbs={breadcrumbs}>
        {/* TODO: SEO */}
        <SEO title="WoWindow" />
        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CategoryCard
            id={wordpressCategory.wordpress_id}
            name={wordpressCategory.name}
            slug={wordpressCategory.slug}
          />
        </div> */}
        <Product title={wordpressCategory.name} edges={posts.edges} />

        <div style={{ margin: 20 }}>
          <Pagination
            totalPage={pageCount}
            activePage={currentPage}
            baseUrl={`/category/${pageContext.slug}`}
          />
        </div>

        <Category edges={data.allWordpressCategory.edges} />

        <style jsx>{``}</style>
      </Layout>
    );
  }
}

export default PostList;

export const pageQuery = graphql`
  query($limit: Int!, $skip: Int!, $categoryId: Int!) {
    wordpressCategory(wordpress_id: { eq: $categoryId }) {
      wordpress_id
      name
      slug
    }
    allWordpressCategory(filter: { slug: { nin: "noad" } }) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressPost(
      limit: $limit
      skip: $skip
      filter: {
        categories: { elemMatch: { wordpress_id: { eq: $categoryId } } }
      }
      sort: { order: DESC, fields: date }
    ) {
      pageInfo {
        pageCount
        perPage
        currentPage
      }
      edges {
        node {
          title
          slug
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
            yoast_wpseo_canonical
          }
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          featured_media {
            source_url
            title
          }
        }
      }
    }
  }
`;
